.Reasons{
    padding: 0 2rem;
    display: flex;
    grid-template:2rem;
}

.left-side{
    flex:1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 0.5rem;
    grid-auto-rows: 1fr;

}

.left-side>img{
    object-fit: cover;

}

.left-side>:nth-child(1){
    width: 14rem;
    grid-row: 1/3;
    height: 28rem;
}
.left-side>:nth-child(2){
    width: auto;
    grid-column: 2/4;
    height: 16rem;
}

.left-side>:nth-child(3){
    width:14rem;
    grid-column: 2/3;
    grid-row: 2;


}
.left-side>:nth-child(4){
    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 11.2rem;

}


.right-side{
    flex:1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    
}
.right-side>span{
    font-weight: bold;
    color: var(--orange);
}
.right-side>div{
    font-weight: bold;
    font-size: 3rem;
}
.details-r{
display: flex;
flex-direction: column;
gap: 1rem;

}

.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}

.details-r>div>img{
    width: 2rem;
    height: 2rem;
}

.partners{
    display: flex;
    gap: 1rem;

}
.partners>img{
    width: 2.5rem;
}
